<template>
  <div class="bg-white pb-2">
    <div class="col-md-12 mb-3 py-1">
      <a-radio-group @change="onChange">
        <a-radio :value="0">
          Все
        </a-radio>
        <a-radio :value="1">
          Да
        </a-radio>
        <a-radio :value="2">
          Нет
        </a-radio>
      </a-radio-group>
    </div>
    <div class="col-md-12 py-0">
      <a-button
        type="primary"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="handleSearch"
      >
        <template #icon>
          <SearchOutlined /></template>
        Ок
      </a-button>
      <a-button
        size="small"
        style="width: 90px"
        @click="handleReset"
      >
        Отмена
      </a-button>
    </div>
  </div>
</template>

<script>
import SearchOutlined from "@ant-design/icons-vue/SearchOutlined";
import {ref, toRefs} from 'vue'

export default {
  name: "sp-bool-filter",
  components: {
    SearchOutlined,
  },
  emits: ['filter'],
  props:{
    column: {
      type: Object
    },
  },
  setup(props, { emit }){
    const radio = ref(0)
    const column = toRefs(props).column
    
    const onChange = function (e){
      radio.value = e.target.value
    }

    const handleSearch = function() {      
      if(radio.value === 0) {
        handleReset()
        return
      }
      
      emit("filter", {field: column.value.dataIndex, equal: radio.value === 1});
    }
    const handleReset = function() {
      radio.value = 0
      emit("filter", {field: column.value.dataIndex});
    }
    
    return {
      radio,
      
      handleSearch,
      handleReset,
      onChange
    }
  }
};
</script>