export const createItem = (name, label, type, validationRules, options, value) => ({
  name, label, type, validationRules, options, value
});

export const itemTypes = {
  string: 'string',
  number: 'number',
  text: 'text',
  boolean: 'boolean',
  reference: 'reference',
  date: 'date',
  datePeriod: 'dateperiod',
  files: 'filecollection',
  representGridColumns: 'representgridcolumn',
  representFormEditor: 'representformeditor',
  representGridFilters: 'representgridfilter',
  commentList: 'commentlist',
  subtable: 'subtable',
  subtableEx: 'subtableex',
  multipleReference: 'multipleReference',
  genericSubtable: 'genericSubtable',
  spEditRequests: 'spEditRequests',
  dependent: 'dependent',
  dependentSubtable: 'dependentsubtable',
  dependentReference: 'dependentreference',
  dynamicFieldType: 'dynamicfieldtype',
  userRoleType: 'userroletype',
  html: 'html',
  label: 'label',
  grid: 'grid',
  tabs: 'tabs',

  gridFilerItemMax: 'gridFilerItemMax',
  gridFilerItemMix: 'gridFilerItemMix',
  gridFilerItemNotContains: 'gridFilerItemNotContains',
  gridFilerItemContains: 'gridFilerItemContains',
  gridFilerItemEqual: 'gridFilerItemEqual',
  gridFilerItemField: 'gridFilerItemField',
};

export const representTypes = {
  represent:{
    gridColumns: itemTypes.representGridColumns
  }
}

export function getTypeName(elementType) {
  if (elementType?.includes(":"))
    return elementType.split(":")[0];
  return elementType;
}

export function getReferenceType(elementType) {
  if (elementType?.includes(":"))
    return elementType.split(":")[1];
  return null;
}

export function getLayoutChildrenProp(elementType) {
  switch (elementType) {
    case itemTypes.tabs: return "tabs";
    case itemTypes.grid: return "columns";
  }
}

export function getLayoutChildren(item) {
  let prop = getLayoutChildrenProp(item.type);
  return item[prop].flatMap(x => x.list);
}