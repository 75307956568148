<template>
  <div class="bg-white pb-2">
    <div class="col-md-12 mb-3 py-1">
      <a-select
        class="w-100"
        :options="selectOptions"
        :defaultValue="selectDefaultValue"
        v-model:value="selectValue"
      />
      <a-input 
        placeholder="Искать в наименовании"
        v-model:value="searchValue"
      />
    </div>
    <div class="col-md-12 py-0">
      <a-button
        type="primary"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="handleSearch"
      >
        <template #icon>
          <SearchOutlined /></template>
        Ок
      </a-button>
      <a-button
        size="small"
        style="width: 90px"
        @click="handleReset"
      >
        Отмена
      </a-button>
    </div>
  </div>
</template>

<script>
import SearchOutlined from "@ant-design/icons-vue/SearchOutlined";
import {ref, toRefs} from 'vue'

export default {
  name: "sp-string-filter",
  components: {
    SearchOutlined,
  },
  emits: ['filter'],
  props:{
    column: {
      type: Object
    },
  },
  setup(props, { emit }){
    const column = toRefs(props).column
    const selectOptions = ref([
      {
        value: "contains",
        label: "Содержит"
      },
      {
        value: "notContains",
        label: "Не содержит"
      }
    ])
    const selectDefaultValue = ref("contains")
    const selectValue = ref("contains")
    const searchValue = ref()

    const handleSearch = function() {
      
      if(!selectValue.value || !searchValue.value) return
      
      let filterData = {field: column.value.dataIndex}
      filterData[selectValue.value] = searchValue.value
      emit("filter", filterData);
    }
    const handleReset = function() {
      emit("filter", {field: column.value.dataIndex});
    }
    
    return {
      selectDefaultValue,
      selectValue,
      selectOptions,
      searchValue,
      
      handleSearch,
      handleReset
    }
  }
};
</script>