<template>
  <div class="bg-white pb-2">
    <div class="col-md-12 mb-3 py-1">
      <a-select
        class="w-100"
        :options="selectOptions"
        :defaultValue="selectDefaultValue"
        v-model:value="selectValue"
      />
      <a-select
        class="w-100"
        showSearch
        optionFilterProp="label"
        :options="searchOptions"
        v-model:value="searchValue"
      />
    </div>
    <div class="col-md-12 py-0">
      <a-button
        type="primary"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="handleSearch"
      >
        <template #icon> <SearchOutlined /></template>
        Ок
      </a-button>
      <a-button size="small" style="width: 90px" @click="handleReset">
        Отмена
      </a-button>
    </div>
  </div>
</template>

<script>
import SearchOutlined from "@ant-design/icons-vue/SearchOutlined";
import { ref, toRefs, onMounted } from "vue";
import api from "@/api";

export default {
  name: "sp-multiple-reference-filter",
  components: {
    SearchOutlined,
  },
  emits: ["filter"],
  props: {
    column: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const column = toRefs(props).column;
    const selectOptions = ref([
      {
        value: "equal",
        label: "Включено",
      },
      {
        value: "notEqual",
        label: "Не включено",
      },
    ]);
    const selectDefaultValue = ref("equal");
    const selectValue = ref("equal");
    const searchValue = ref();
    const searchOptions = ref([]);

    const handleSearch = function () {
      if (!selectValue.value || !searchValue.value) 
        return;

      let filterData = { field: getFieldName() };
      filterData[selectValue.value] = searchValue.value;
      emit("filter", filterData);
    };
    const handleReset = function () {
      emit("filter", { field: getFieldName() });
    };
    
    const getFieldName = function(){
      return column.value.dataIndex
    }

    onMounted(async () => {
      if(column.value.dataIndex === "spDevelopmentTermNine"){
        let options = await api.crud.gridData("DevelopmentTermNine")
        searchOptions.value = options.data.map(x => ({ label: x.title, value: x.id }));
      }
      if(column.value.dataIndex === "spDevelopmentTermEleven"){
        let options = await api.crud.gridData("DevelopmentTermEleven")
        searchOptions.value = options.data.map(x => ({ label: x.title, value: x.id }));
      }
    });

    return {
      selectDefaultValue,
      selectValue,
      selectOptions,
      searchValue,
      searchOptions,

      handleSearch,
      handleReset,
    };
  },
};
</script>