<template>
  <div class="bg-white pb-2">
    <div class="col-md-12 mb-3 py-1">
      <a-date-picker
        class="mb-2"
        placeholder="c"
        format="DD.MM.YYYY"
        v-model:value="min"
      />
      <a-date-picker
        class="mb-2"
        placeholder="по"
        format="DD.MM.YYYY"
        v-model:value="max"
      />
    </div>
    <div class="col-md-12 py-0">
      <a-button
        type="primary"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="handleSearch"
      >
        <template #icon>
          <SearchOutlined /></template>
        Ок
      </a-button>
      <a-button
        size="small"
        style="width: 90px"
        @click="handleReset"
      >
        Отмена
      </a-button>
    </div>
  </div>
</template>

<script>
import SearchOutlined from "@ant-design/icons-vue/SearchOutlined";
import {ref, toRefs} from 'vue'

export default {
  name: "sp-date-period-filter",
  components: {
    SearchOutlined,
  },
  emits: ['filter'],
  props:{
    column: {
      type: Object
    },
  },
  setup(props, { emit }){
    const column = toRefs(props).column
    const min = ref()
    const max = ref()

    const handleSearch = function() {
      if(!min.value && !max.value) return

      let filterData = {field: column.value.dataIndex}

      if(min.value){
        filterData.min = min.value
        filterData.min.set({
          hour:   0,
          minute: 0,
          second: 0
        });
      }
      if(max.value){
        filterData.max = max.value
        filterData.max.set({
          hour:   0,
          minute: 0,
          second: 0
        });
        filterData.max.add(1, 'days');
      }

      emit("filter", filterData);
    }
    const handleReset = function() {
      emit("filter", {field: column.value.dataIndex});
    }

    return {
      min,
      max,

      handleSearch,
      handleReset
    }
  }
};
</script>