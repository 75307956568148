<template>
  <div class="bg-white pb-2">
    <div class="col-md-12 mb-3 py-1">
      <a-input
        class="col-6"
        placeholder="с"
        v-model:value="min"
        type="number"
      />
      <a-input
        class="col-6"
        placeholder="по"
        v-model:value="max"
        type="number"
      />
    </div>
    <div class="col-md-12 py-0">
      <a-button
        type="primary"
        size="small"
        style="width: 90px; margin-right: 8px"
        @click="handleSearch"
      >
        <template #icon>
          <SearchOutlined /></template>
        Ок
      </a-button>
      <a-button
        size="small"
        style="width: 90px"
        @click="handleReset"
      >
        Отмена
      </a-button>
    </div>
  </div>
</template>

<script>
import SearchOutlined from "@ant-design/icons-vue/SearchOutlined";
import {ref, toRefs} from 'vue'

export default {
  name: "sp-number-period-filter",
  components: {
    SearchOutlined,
  },
  emits: ['filter'],
  props:{
    column: {
      type: Object
    },
  },
  setup(props, { emit }){
    const column = toRefs(props).column
    const min = ref()
    const max = ref()

    const handleSearch = function() {      
      if(!min.value && !max.value) return
      
      let filterData = {field: column.value.dataIndex}
      
      if(min.value)
        filterData.min = min.value      
      if(max.value)
        filterData.max = max.value
      
      emit("filter", filterData);
    }
    const handleReset = function() {
      emit("filter", {field: column.value.dataIndex});
    }
    
    return {
      min,
      max,
      
      handleSearch,
      handleReset
    }
  }
};
</script>