<template>
  <div :class="`grid grid${represent.systemName}`">
    <div class="card" style="margin-bottom: 0">
      <div class="card-header" v-if="toolbarVisible && !readonly">
        <ListViewToolbar
          :represent="represent"
          :editEnable="editEnable"
          :deleteEnable="selectedRows.length !== 0"
          :allFilters="allFilters"
          :gridColumns="gridColumns"
          :selectedRowKeys="selectedRowKeys"
          @add="onAdd"
          @edit="onEdit"
          @remove="onRemove"
          @refresh="loadGridData"
          @filter="onFilter"
        />
      </div>
      <a-table
        :scroll="{
          x: 250*gridColumns.length +'px', 
          y: 'calc(100vh - 400px)'
        }"
        :pagination="{ 
          pageSize: 20, 
          onChange: onPageChange, 
          total: totalCount,
          showTotal: (total, range) => `${range[0]}-${range[1]} из ${total}`
        }"
        :loading="loading"
        :dataSource="gridData"
        :columns="gridColumns"
        :customRow="rowClick"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        rowKey="id"
      >
        <template #boolFilter="{ column }">
          <BoolFilter :column="column" @filter="onFilter" />
        </template>
        
        <template #referenceFilter="{ column }">
          <ReferenceFilter :column="column" @filter="onFilter" />
        </template>

        <template #multipleReferenceFilter="{ column }">
          <MultipleReferenceFilter :column="column" @filter="onFilter" />
        </template>

        <template #stringFilter="{ column }">
          <StringFilter :column="column" @filter="onFilter" />
        </template>

        <template #numberPeriodFilter="{ column }">
          <NumberPeriodFilter :column="column" @filter="onFilter" :class="`gridNumberFilter-${column.dataIndex}`"/>
        </template>

        <template #datePeriodFilter="{ column }">
          <DatePeriodFilter :column="column" @filter="onFilter" />
        </template>

        <template #fileCollectionFilter="{ column }">
          <FileCollectionFilter :column="column" @filter="onFilter" />
        </template>

        <template #filterIcon="filtered">
          <filter-outlined
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </template>

        <template #boolCell="{ record, column }">
          <a-switch v-model:checked="record[column.dataIndex]" disabled />
        </template>

        <template #referenceCell="{ record, column }">
          <span>
            {{record[column.dataIndex]?.title}}
          </span>
        </template>

        <template #multipleReferenceCell="{ record, column }">
          <span>
            <MultipleReferenceCell :items="record[column.dataIndex]" />
          </span>
        </template>

        <template #userCell="{ record, column }">
          <span>
            {{record[column.dataIndex]?.login}}
          </span>
        </template>

        <template #fileCollectionCell="{ record, column }">
          <span v-if="!!record[column.dataIndex]?.files?.length">
            <FileCollectionCell :fileIds="record[column.dataIndex].files" />
          </span>
        </template>

        <template #dateCell="{ record, column }">
          <span>
            {{ record[column.dataIndex] ? moment(record[column.dataIndex]).format("DD.MM.YYYY") : "" }}
          </span>
        </template>

        <template #datePeriodCell="{ record, column }">
          <span>
            {{ 
              record[column.dataIndex]?.length 
                ? moment(record[column.dataIndex][0]).format("DD.MM.YYYY") + " - "
                  + moment(record[column.dataIndex][1]).format("DD.MM.YYYY")
                : "" 
            }}
          </span>
        </template>
        
      </a-table>
    </div>

    <a-modal 
      v-model:visible="modalVisible"
      width="100%"
      :class="style.formGrid"
    >
      <template #title>
        <a-tag 
          v-if="modalItem && modalItem.id"
          style="margin-left: -4px"
        >
          ID {{ modalItem.id }}
        </a-tag>
        <span class="align-bottom">
          {{ represent.customName }}
        </span>
      </template>

      <FormGenerator
        v-if="modalVisible"
        :readonly="!allowedToEdit"
        :items="modalFormFields"
        :represent="represent"
        :objectData="modalItem"
        @initialized="onFormInitialized"
      />

      <template #footer>
        <a-button
          v-if="useAdvancedForm && isAdmin && editing"
          :loading="modalSaving"
          @click="resetbp()"
        >Обнулить бизнес-процесс</a-button
        >
        
        
        <a-button
          :disabled="!allowedToEdit"
          :loading="modalSaving"
          @click="save()"
          >Сохранить</a-button
        >

        <a-button
          :disabled="!allowedToEdit"
          type="primary"
          :loading="modalSaving"
          @click="save(true)"
          >Сохранить и закрыть</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import style from '@/css/components/grid.module.scss'

import CrudService from "@/services/crud-service";
import ListViewToolbar from "@/views/base/ListViewToolbar";
import BoolFilter from "@/components/grid-filters/sp-bool-filter";
import StringFilter from "@/components/grid-filters/sp-string-filter";
import ReferenceFilter from "@/components/grid-filters/sp-reference-filter";
import MultipleReferenceFilter from "@/components/grid-filters/sp-multiple-reference-filter";
import NumberPeriodFilter from "@/components/grid-filters/sp-number-period-filter";
import DatePeriodFilter from "@/components/grid-filters/sp-date-period-filter";
import FileCollectionFilter from "@/components/grid-filters/sp-file-collection-filter";
import FileCollectionCell from "@/components/cells/sp-file-collection-cell.vue";
import MultipleReferenceCell from "@/components/cells/multiple-reference-cell.vue";
import { error, success, info } from "@/helpers/notifications";
import { onMounted, toRefs, watch, ref, defineAsyncComponent, computed } from "vue";
import FilterOutlined from "@ant-design/icons-vue/FilterOutlined";
import { getLayoutChildren } from "@/helpers/form-generator";
import {cloneDeep} from "lodash";
import moment from "moment";
import { showConfirm } from "@/helpers/modals";
import api from "@/api";

export default {
  name: "grid",
  components: {
    FormGenerator: defineAsyncComponent(() => 
      import("@/components/form-generation/sp-form-generator.vue")),
    ListViewToolbar,
    BoolFilter,
    StringFilter,
    ReferenceFilter,
    MultipleReferenceFilter,
    DatePeriodFilter,
    NumberPeriodFilter,
    FileCollectionFilter,
    FileCollectionCell,
    MultipleReferenceCell,
    FilterOutlined,
  },
  props: {
    representService: {
      type: Object,
    },
    crudService: {
      type: Object,
      default: null
    },
    patchProps: {
      type: Object,
    },
    filters: {
      type: Array,
    },
    hiddenFields: {
      type: Array,
      default: () => []
    },
    toolbarVisible: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    allowedToEdit() {
      return !this.readonly && this.$auth.isLoggedIn();
    },
    useAdvancedForm() {
      return this.represent?.entityName?.toLowerCase() === "sampleprogram";
    },
  },
  emits: ["selectionChanged"],
  // TODO: разделить на части
  setup(props, { emit }) {
    const {
      hiddenFields,
      representService,
      crudService: customCrudService,
      filters: additionalFilters,
      patchProps,
    } = toRefs(props);
    let modalItem = ref({});
    let modalForm = ref({});

    const selectedRows = ref([]);
    const selectedRowKeys = ref([]);
    const gridData = ref([]);
    const gridColumns = ref([]);
    const formFields = ref([]);
    const formFieldsValidation = ref({});
    const loading = ref(false);
    const modalVisible = ref(false);
    const modalSaving = ref(false);
    const filtered = ref(true);
    const filters = ref([]);
    const allFilters = ref([]);
    const pageSize = ref(20);
    const pageSkip = ref(0);
    const totalCount = ref(0);

    const represent = ref({});
    const userPermission = ref({});
    const crudService = ref({});
    const editEnable = ref(false);
    const role = ref({});

    const isAdmin = computed(() => role.value?.roleType === 1)

    const editing = computed(() =>  modalItem?.value?.id)

    watch(representService, () => {
      loadGridData();
    });

    watch(additionalFilters, () => {
      loadGridData();
    });

    const onFormInitialized = (form) => (modalForm.value = form);

    const modalFormFields = ref([]);
    const getModalFormFields = () => {
      let enabledFields = userPermission.value.addOrUpdateAllow || [];
      let allowedInnerTypes = userPermission.value.allowedInnerTypes || [];
      let allowedSelectOptions = userPermission.value.allowedOptions || [];
      let allProps = userPermission.value.allProps || [];
      
      const processItem = item => {
        if(item.name) {
          item.required = formFieldsValidation.value[item.name]?.required;
          item.validationRules = [
            { required: item.required },
          ];

          if(modalItem.value){
            item.value = modalItem.value[item.name];

            if(!item.options)
              item.options = {};
            
            item.options.disabled = !enabledFields.some((ef) => ef === item.name)
              && !allowedInnerTypes.some((ef) => ef === item.options.innerType)
              && !allowedSelectOptions[item.name]
              && allProps.some((e) => e == item.name)
            
            let allowedSelectOpt = allowedSelectOptions[item.name]
            if(allowedSelectOpt)
              item.options.allowedSelectOptions = allowedSelectOpt
          }
        }
      }

      return formFields.value
        .filter(function(item){
          return hiddenFields.value.every(function(s){
            return s !== item.name
          })
        })
        .map((item) => {
          if (item.isLayout) { 
            let children = getLayoutChildren(item);
            for (const child of children) 
              processItem(child);

            return item;
          }

          processItem(item);

          return item;
        });
    };

    const getSlotsForType = function(type){
      
      let typeKey = type;
      if (type?.includes(":"))
        typeKey = type.split(":")[0]
      
      let slots = {
        filterIcon: "filterIcon"
      }
      
      if(typeKey === "boolean"){
        slots.filterIcon = "filterIcon"
        slots.filterDropdown = "boolFilter"
        slots.customRender = "boolCell"
      }

      if(typeKey === "reference" || typeKey === "dependent"){
        slots.filterIcon = "filterIcon"
        slots.filterDropdown = "referenceFilter"
        slots.customRender = "referenceCell"
      }

      if(typeKey === "multipleReference"){
        slots.filterIcon = "filterIcon"
        slots.filterDropdown = "multipleReferenceFilter"
        slots.customRender = "multipleReferenceCell"
      }
      
      if(typeKey === "string"){
        slots.filterIcon = "filterIcon"
        slots.filterDropdown = "stringFilter"
      }

      if(typeKey === "number"){
        slots.filterIcon = "filterIcon"
        slots.filterDropdown = "numberPeriodFilter"
      }

      if(typeKey === "user"){
        slots.customRender = "userCell"
      }

      if(typeKey === "filecollection"){
        slots.filterIcon = "filterIcon"
        slots.filterDropdown = "fileCollectionFilter"
        slots.customRender = "fileCollectionCell"
      }

      if(typeKey === "date"){
        slots.customRender = "dateCell";
        slots.filterIcon = "filterIcon";
        slots.filterDropdown = "datePeriodFilter";
      }

      if (typeKey === "dateperiod") {
        slots.customRender = "datePeriodCell";
      }
      
      return slots
    }
    
    const loadGridData = async function () {
      loading.value = true;

      role.value = await api.userManager.myRole();

      let gridFilters = Object.values(filters.value).concat(
        additionalFilters.value
      );

      represent.value = await representService.value.get();

      if (!represent.value) {
        error("Представление не найдено");
        return;
      }
      
      allFilters.value = gridFilters = gridFilters
        .concat(represent.value.filters);

      crudService.value = customCrudService?.value ?? new CrudService(represent.value.entityName);
      let gridResult = await crudService.value.gridData({ Items: gridFilters }, pageSkip.value, pageSize.value);
      gridData.value = gridResult.data; 
      totalCount.value = gridResult.total;
      
      gridColumns.value = represent.value.gridColumns;

      gridColumns.value.forEach(function(item) {
        if (item.dataIndex === 'id' )
          item.width = '90px';
        return null;
      });
      
      gridColumns.value.forEach(function(item, i, arr) {
        item.slots = getSlotsForType(item.type)
      });
      
      formFields.value = represent.value.formFields.map(x => cloneDeep(x));

      formFieldsValidation.value = {};

      represent.value.fieldsValidation?.forEach(function (item) {
        formFieldsValidation.value[item.name.toLowerCase()] = {
          required: item.required,
          validation: item.validation,
        };
      });

      selectedRows.value = [];
      loading.value = false;
    };

    const save = async function (close) {
      let validation = await modalForm.value.validate();    
      
      if(validation && validation.status !== "success"){
        for (const mes of validation.messages){
          error(mes);
        }
        
        return;
      }
      
      modalSaving.value = true;

      let model = { ...modalForm.value.changedState };

      try {
        if (Object.keys(model).length === 0) {
          info("Нет изменений для сохранения");
          return;
        }

        if (!modalItem.value){
          
          for (var k in patchProps.value) {
            model[k] = patchProps.value[k];
          }

          await crudService.value.create(model);
        }
        else {
          model.id = modalItem.value.id;
          await crudService.value.update(model);
        }
        modalForm.value.clearChanges();
        success("Сохранено");
      } catch (e) {
        error("Ошибка при сохранении");
        close = false;
      } finally {
        // TODO: fix fast indicator show-hide
        modalSaving.value = false;
        
        if (close) 
          hideModal();

        await loadGridData();
        
        if (modalItem.value?.id) {
          modalItem.value = await crudService.value.get(modalItem.value.id);
          userPermission.value = await crudService.value.getPermissions(modalItem.value.id);
          modalFormFields.value = getModalFormFields();
        }
      }
    };

    const resetbp = async function (){
      showConfirm({ 
        text: "Вы точно хотите обнулить бизнес-процесс?",
        ok: async () => {
          await api.crud.resetspbp(modalItem.value.id);
          save(true);
        },
      });
    };

    const hideModal = function () {
      modalVisible.value = false;
      modalSaving.value = false;
      modalItem.value = null;
    };
    const showModal = () => {
      modalFormFields.value = getModalFormFields();
      modalVisible.value = true;
    };
    const onAdd = async function () {

      userPermission.value = await crudService.value.getPermissions(0);

      let enabledFields = userPermission.value.addOrUpdateAllow || [];
      let allowedInnerTypes = userPermission.value.allowedInnerTypes || [];
      let allowedSelectOptions = userPermission.value.allowedOptions || [];
      let allProps = userPermission.value.allProps || [];

      const processItem = item => {
        if(!item.options)
          item.options = {};

        item.options.disabled = !enabledFields.some((ef) => ef === item.name)
          && !allowedInnerTypes.some((ef) => ef === item.options.innerType)
          && !allowedSelectOptions[item.name]
          && allProps.some((e) => e == item.name)

        let allowedSelectOpt = allowedSelectOptions[item.name]
        if(allowedSelectOpt)
          item.options.allowedSelectOptions = allowedSelectOpt
      }

      formFields.value = represent.value.formFields.filter(function(item){
        return hiddenFields.value.every(function(s){
          return s !== item.name
        })
      }).map((x) => {
        let item = cloneDeep(x)

        if (item.isLayout) {
          let children = getLayoutChildren(item);
          for (const child of children)
            processItem(child);

          return item;
        }

        processItem(item);

        return item;
      });
      
      modalItem.value = null;
      showModal();
    };

    const onRemove = function () {
      showConfirm({ 
        text: "Вы действительно хотите удалить выбранные записи?",
        ok: async () => {
          const promises = selectedRows.value.map((item) => {
            return crudService.value.remove(item.id);
          });

          await Promise.all(promises);
          await loadGridData();
        },
      });
    };

    const openItem = async (id) => {
      modalItem.value = await crudService.value.get(id);
      userPermission.value = await crudService.value.getPermissions(id);
      showModal();
    };
    
    const onEdit = async () => {
      await openItem(selectedRows.value[0].id);
    };
    const rowClick = (record, index) => ({
      onClick: async () => {
        await openItem(record.id);
      },
    });

    const onSelectChange = function (srk, sr) {
      selectedRowKeys.value = srk;
      selectedRows.value = sr;

      editEnable.value = !!selectedRowKeys.value.length;
      
      emit("selectionChanged", srk, sr);
    };

    const onFilter = async function (filter) {
      filters.value[filter.field] = filter;

      await loadGridData();
    };

    const onPageChange = async (number, size) => {
      pageSize.value = size;
      pageSkip.value = (number - 1) * size;

      await loadGridData();
    }

    onMounted(async () => {
      await loadGridData();
    });
    return {
      style,
      modalItem,
      selectedRows,
      selectedRowKeys,
      loading,
      gridData,
      gridColumns,
      totalCount,
      modalVisible,
      modalSaving,
      modalForm,
      formFields,
      modalFormFields,
      filtered,
      allFilters,
      represent,
      editEnable,

      isAdmin,
      editing,
      onFormInitialized,
      save,
      resetbp,
      rowClick,
      onRemove,
      onAdd,
      onEdit,
      onSelectChange,
      onFilter,
      onPageChange,
      loadGridData,

      moment
    };
  },
};
</script>