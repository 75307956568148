<template>
  <a-space class="ListViewToolbar">
    <a-button
      v-if="isLoggedIn"
      title="Создать новую запись"
      :disabled="!addEnable || !permissionsComputed.add"
      @click="() => $emit('add')"
    >
      <template #icon><PlusOutlined /></template>
    </a-button>

    <a-button
      v-if="isLoggedIn"
      title="Отредактировать выбранную запись"
      :disabled="!editEnable || !permissionsComputed.edit"
      @click="() => $emit('edit')"
    >
      <template #icon><EditOutlined /></template>
    </a-button>

    <a-button
      v-if="isLoggedIn"
      title="Удалить выбранные записи"
      :disabled="!deleteEnable || !permissionsComputed.remove"
      @click="() => $emit('remove')"
    >
      <template #icon><DeleteOutlined /></template>
    </a-button>

    <!-- TODO: styling -->
    <a-upload
      v-if="showImportButton"
      class="d-flex"
      :file-list="fileList"
      :remove="handleRemove"
      :before-upload="beforeUpload"
    >
      <a-button>
        <upload-outlined />
        Выберите файл
      </a-button>
    </a-upload>
    <a-button
      v-if="fileList.length > 0"
      type="primary"
      :loading="uploading"
      @click="handleUpload"
    >
      {{ uploading ? "Загружаю" : "Начать загрузку" }}
    </a-button>

    <a-button
      v-if="showExportButton"
      @click="exportData"
    >
      <template #icon><DownloadOutlined /></template>
    </a-button>
    
    <slot name="moreButtons">
    </slot>

    <div v-if="choosenFilters.length > 0" class="ListViewToolbar__filters">
      <div v-for="field in choosenFilters" :key="field.field">
        <ListViewToolbarActiveFilter
          v-if="Object.keys(field).length > 1 && field.field != 'ProgramStatus.Title'"
          :field="field"
          :gridColumns="gridColumns"
          @filter="handleReset"
          class="ListViewToolbar__filters__field"
        />
      </div>
    </div>

  </a-space>
</template>

<script>
import api from "@/api";
import axios from "axios";
import { UploadOutlined, PlusOutlined, DownloadOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons-vue";
import { success, error } from "@/helpers/notifications";
import ListViewToolbarActiveFilter from "./ListViewToolbarActiveFilter"

export default {
  name: "list-view-toolbar",
  components: { UploadOutlined, PlusOutlined, DownloadOutlined, EditOutlined, DeleteOutlined, ListViewToolbarActiveFilter},
  emits: ["add", "edit", "remove", "refresh"],
  props: {
    represent: {
      type: Object,
    },
    editEnable: {
      type: Boolean,
      default: false,
    },
    addEnable: {
      type: Boolean,
      default: true,
    },
    deleteEnable: {
      type: Boolean,
      default: false,
    },
    allFilters: {
      type: Array,
      default: () => []
    },
    gridColumns: {
      type: Array,
      default: () => []
    },
    selectedRowKeys: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      role: {
        programAddRemove: true,
      },
      permission: {
        add: true,
        edit: true,
        remove: true,
      },
      importTypes: [],
      exportTypes: ["SampleProgram"],
      fileList: [],
      uploading: false
    };
  },
  computed: {
    choosenFilters() {
      return this.allFilters.filter(function (el) {
        return el != null;
      });
    },
    isLoggedIn() {
      return this.$auth.isLoggedIn();
    },
    entityName() {
      return this.represent?.entityName;
    },
    permissionsComputed() {
      if (this.represent?.entityName === "SampleProgram") {
        return {
          add: this.role.programAddRemove,
          remove: this.role.programAddRemove,
          edit: this.permission.edit,
        };
      } else {
        return this.permission;
      }
    },
    showImportButton() {
      if (!this.isLoggedIn || !this.entityName)
        return false;

      const entityName = this.represent.entityName.toLowerCase();
      return this.importTypes.some(x => x.toLowerCase() === entityName);
    },
    showExportButton() {
      if (!this.entityName)
        return false;

      const entityName = this.represent.entityName.toLowerCase();
      return this.exportTypes.some(x => x.toLowerCase() === entityName);
    }
  },
  methods: {
    async handleUpload() {
      const formData = new FormData();
      this.fileList.forEach((file) => {
        formData.append("files[]", file);
      });
      this.uploading = true;
      try {
        await axios.post(
          "/Upload/" + this.entityName,
          formData,
          { headers: { "Content-Type": "multipart/form-data" }}
        );

        this.fileList = [];
        this.uploading = false;
        success("Загрузка прошла успешно.");
        this.$emit("refresh");
      }
      catch(e) {
        this.uploading = false;
        error("Ошибка загрузки.");
        console.error(e);
      }
    },
    beforeUpload(file) {
      this.fileList.push(file);
      return false;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    exportData() {
      let data = {
        gridParams: { filters: { Items: this.allFilters } },
        representName: this.represent.systemName
      };

      if (this.selectedRowKeys?.length)
        data.ids = this.selectedRowKeys;

      axios({
        method: 'post',
        url: 'sample_program/export',
        responseType: 'arraybuffer',
        data: data
      }).then(function(response) {
        let blob = new Blob([response.data], { type: 'application/xlsx' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Export.xlsx';
        link.click();
      })
    },
    handleReset(field) {
      this.$emit("filter", field);
    }
  },
  async created() {
    this.role = await api.userManager.myRole();
    this.importTypes = await api.upload.getAllowedTypes();
  },
};
</script>