<template>
  <div v-for="info in infos" :key="info.url">
    <a :href="info.url" target="_blank" @click.stop>
      {{info.name}}
    </a>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "sp-file-collection-cell",
  props: {
    fileIds: { type: Array, default: () => {[]} }
  },
  data() {
    return {
      infos: []
    };
  },
  watch: {
    fileIds: {
      immediate: true,
      async handler(fileIds) {
        let results = [];
        for (let id of fileIds) {
          var res = await api.filestorage.getInfo(id);
          results.push(res);
        }
        // console.log(results);
        this.infos = results;
      }
    }
  }
}
</script>