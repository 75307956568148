<template>
  <div>
    <span v-for="title in titles" :key="title">
      {{title}};  
    </span>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "multiple-reference-cell",
  props: {
    items: { type: Array, default: () => {[]} }
  },
  data() {
    return {
      titles: []
    };
  },
  methods: {
    async addTitle(type, id){
      this.titles.push((await api.crud.get(type, id)).title)
    }
  },
  watch: {
    items: {
      immediate: true,
      async handler(items) {
        const vm = this;

        vm.titles = [];

        items.forEach(function(item){
          if(item.developmentTermNineId){
            vm.addTitle("DevelopmentTermNine", item.developmentTermNineId)
          }
          if(item.developmentTermElevenId){
            vm.addTitle("DevelopmentTermEleven", item.developmentTermElevenId)
          }
        })
      }
    }
  }
}
</script>