<template>
  <a-button @click="handleReset">
    {{fieldTitle}}
    <CloseOutlined />
  </a-button>
</template>
<script>
import {  CloseOutlined } from "@ant-design/icons-vue";
import { ref, toRefs, onMounted } from "vue";

export default {
  emits: ['filter'],
  props:{
    field: {
      type: Object,
      default: null
    },
    gridColumns: {
      type: Array,
      default: () => []
    },
  },
  components: { CloseOutlined },
  setup(props, { emit }){
    const field = toRefs(props).field;
    const gridColumns = toRefs(props).gridColumns;
    const fieldTitle = ref(null);
    onMounted(async () => {
      const activeFilter = gridColumns.value.filter(column => column.dataIndex + ".id" === field.value.field || column.dataIndex === field.value.field);
      fieldTitle.value = activeFilter[0]?.title;
      // console.log(fieldTitle.value);
    });
    const handleReset = function() {
      emit("filter", {field: field.value.field});
    }
    return {
      fieldTitle,
      handleReset
    }
  }
}
</script>