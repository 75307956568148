import api from "@/api";

export default class CrudService {
  constructor(type) {
    this.type = type;
  }

  async gridData(filters = {}, pageSkip = 0, pageSize = 0) {
    return await api.crud.gridData(this.type, filters, pageSkip, pageSize);
  }

  async remove(id) {
    return await api.crud.remove(this.type, id);
  }

  async update(obj) {
    return await api.crud.update(this.type, obj);
  }

  async create(obj) {
    return await api.crud.create(this.type, obj);
  }

  async get(id) {
    return await api.crud.get(this.type, id);
  }

  async getPermissions(id) {
    return await api.crud.getPermissions(this.type, id);
  }
}
