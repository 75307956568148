import api from "@/api";

export default class RepresentService {
  constructor(represent) {
    this.represent = represent;
  }
  
  name(){ 
    return this.represent;    
  }

  async get() {    
    return await api.entityConfig.represent(this.represent)
  }
}
